import React, { useMemo } from "react";
import _ from "lodash";
import Layout from "@components/layout";
import queryMenu from "@graphql/menu";
import { formatGQLQuery } from "@utils";
import {
    popularArticles as popularArticlesQuery,
} from "@graphql/article";
import { allCategories } from "@graphql/category";
import contactAndAddress from "@graphql/footer";

export default function Pihk(props) {
    const {
        menu,
        contact = {},
        address = {},
        additionalSearchData = null,
    } = props.serverData;

    const data = useMemo(() => {
        if (additionalSearchData) {
            return additionalSearchData?.Data[0] ?? {}
        }
        return {};
    }, [additionalSearchData])
    return (
        <Layout menu={menu} contact={contact} address={address} {...props}>
            <main className="flex-1">
                <div className="container my-10">
                    <div className="flex flex-col items-start gap-10 md:flex-row">
                        <div className="w-full md:w-full">
                            <Content data={data} />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

const Content = ({
    data,
}) => {

    if (_.isEmpty(data)) {
        return (
            <div className="text-center">
                <p className="mt-4 text-gray-400">
                    Kami tidak menemukan hasil.
                </p>
            </div>
        );
    }
    return (
        <div className="my-4 w-full relative overflow-x-auto">
            <div className="text-3xl font-semibold text-gray-400">
                Detail <span className="text-fontPrimary">{data?.nama_pihk}</span>
            </div>
            <div className="mt-3">
                <table>
                    <tr>
                        <td>Tanggal SK</td>
                        <td>:</td>
                        <td>{data.tanggal_sk}</td>
                    </tr>
                    <tr>
                        <td>Nomor SK</td>
                        <td>:</td>
                        <td>{data.nomor_sk}</td>
                    </tr>
                    <tr>
                        <td>Status PIHK</td>
                        <td>:</td>
                        <td>{data.status_pihk}</td>
                    </tr>
                    <tr>
                        <td>Akreditasi</td>
                        <td>:</td>
                        <td>{data.nilai_akreditasi}</td>
                    </tr>
                    <tr>
                        <td>Nama Direktur</td>
                        <td>:</td>
                        <td>{data.nama_direktur}</td>
                    </tr>
                    <tr>
                        <td>No Telepon</td>
                        <td>:</td>
                        <td>{data.nomor_telepon}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{data.email}</td>
                    </tr>
                    <tr>
                        <td>Alamat</td>
                        <td>:</td>
                        <td>{data.alamat}</td>
                    </tr>
                </table>
            </div>
        </div>
    );
};

export async function getServerData({ query, params }) {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const isoThirtyDaysAgo = thirtyDaysAgo.toISOString().toString();
  
    try {
        const response = await fetch(process.env.GRAPHQL_URL_SSR, {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: formatGQLQuery(
                    [queryMenu, allCategories, popularArticlesQuery, contactAndAddress],
                    [
                        { key: "$popularArticlesLimit", type: "Int!" },
                        { key: "$popularArticlesMaxDate", type: "DateTime!" },
                    ]
                ),
                variables: {
                    popularArticlesLimit: 5,
                    popularArticlesMaxDate: isoThirtyDaysAgo,
                },
            }),
        });
        let additionalSearchData;
        if (params && params?.code) {
            const url = process.env.SISKOHAT_URL + "/pihk/detailpihk/" + params?.code;
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-key": process.env.XKEY_SISKOHAT,
                },
                agent: new (require('https').Agent)({ rejectUnauthorized: false }) // Bypass SSL verification
            }
            additionalSearchData = await fetch(url, requestOptions).then(res => {
                if (res.ok) return res.json();
                return null;
            });
        }

        const { data } = await response.json();
        return {
            props: {
                menu: data?.menu?.data,
                categories: data?.allCategories?.data,
                popularArticles: data?.popularArticles?.data,
                query,
                contact: data?.contact?.data,
                address: data?.address?.data,
                additionalSearchData
            },
            status: 200,
        };
    } catch (error) {
        return {
            status: 500,
        };
    }
}
